:root {
    --primary-color: #9D0759;
    --primary-color-rgb: 157, 7, 89;
    --secondary-color: #12676A;
    --primary-color-rgb: 18, 103, 106;
    --secondary-color-light: #1EABB0;
    --tertiary-color: #fffbeb;
    --white-color: #ffffff;
    --black-color: #000000;
    --danger-color: #e60480ce;
    --box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);   
}       

$box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
