@use "@angular/material" as mat;

.dense-1 {
    @include mat.all-component-densities(-1);
}

.dense-2 {
    @include mat.all-component-densities(-2);
}

/* You can add global styles to this file, and also import other style files */
@import "assets/scss/style.scss";

@media (-webkit-device-pixel-ratio: 1.5) {
    * {
        zoom: 0.95;
    }
    .map * {
        zoom: 1;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $primary-font;
    // background-color: var(--tertiary-color);
}

html,
body {
    height: 100%;
    font-size: 16px;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
    html {
        font-size: 10px !important;
    }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
    html {
        font-size: 11px !important;
    }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    html {
        font-size: 12px !important;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
    html {
        font-size: 13px !important;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (max-width: 1400px) {
    html {
        font-size: 15px !important;
    }
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mdc-slider__track--inactive {
    opacity: 1 !important;
    border: 1px solid var(--primary-color);
}
.mat-mdc-slider .mdc-slider__track--active_fill {
    border: 1px solid var(--primary-color);
    background-image: linear-gradient(
        90deg,
        rgba(18, 103, 106, 1) 0%,
        rgb(155, 227, 254) 50%,
        rgba(157, 7, 89, 1) 100%
    );
    border-image-slice: 1;
    border-radius: 30px;
}

.mat-mdc-button:not(:disabled) {
    color: #ffffff;
    background: #12676a !important;
}
.custom-timepicker-css {
    .mat-toolbar.mat-primary {
        --mat-toolbar-container-background-color: #12676a !important;
        --mat-toolbar-container-text-color: #ffffff !important;
    }
    .mat-mdc-button:not(:disabled) {
        color: #ffffff;
        background: #12676a !important;
    }
}


